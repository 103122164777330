import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { detect } from 'detect-browser';

import { BrowserCompatibilityService } from '@sign-in/services/browser-compatibility/browser-compatibility.service';
import { PlatformService } from 'universal';

@Injectable({
  providedIn: 'root',
})
export class BrowserCompatibilityGuard {
  ps = inject(PlatformService);
  router = inject(Router);
  bcs = inject(BrowserCompatibilityService);

  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const onCompatibilityPage = state.url.includes('/outdated-browser');
    if (this.ps.isServer()) return !!onCompatibilityPage;
    const browser = detect();
    if (!browser?.name) return true;
    const browserName = browser.name;
    /*
     * This validation is actually done against the rendering engine that the browser implements.
     * That means that we will get things like Brave and DuckDuckGo being detected as Chrome, etc.
     * because they all use the Blink engine.
     */
    const isSupported =
      browserName === 'chrome' ||
      browserName === 'edge' ||
      browserName === 'edge-chromium' ||
      browserName === 'edge-ios' ||
      browserName === 'firefox' ||
      browserName === 'safari' ||
      browserName === 'ios' ||
      browserName === 'ios-webview' ||
      browserName === 'android' ||
      browserName === 'chromium-webview' ||
      browserName === 'crios' ||
      browserName === 'fxios';
    if (isSupported) {
      this.bcs.clear();
      if (onCompatibilityPage) {
        this.router.navigateByUrl('/');
        return false;
      }
      return true;
    } else {
      if (this.bcs.getDidAck() || onCompatibilityPage) return true;
      this.router.navigateByUrl('/outdated-browser');
      return false;
    }
  }
}
