import { Component, inject } from '@angular/core';
import { PlatformService } from 'universal';

import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  template: `
    <app-google-analytics></app-google-analytics>
    <aus-alert-banner
      *ngIf="aps.showGlobalBanner"
      [data]="data"
      class="banner"
    ></aus-alert-banner>
    <aus-spinner
      *ngIf="showSpinner"
      [absolute]="true"
      size="SMALL"
      oriented="VERTICAL"
      text=""
    ></aus-spinner>
    <router-outlet></router-outlet>
  `,
  styles: [`
      .banner {
        ::ng-deep 
          .link--standalone .link__label:not([class*=underline]) {
          text-decoration: underline !important;
        }
      }
  `]
})
export class AppComponent {
  aps = inject(AppService);
  showSpinner = true;
  title = 'aramark-signin';
  data = {
    text: 'Aramark Uniform Services is now Vestis™.',
    cta: {
      __typename: 'AcfLink',
      url: 'https://www.vestis.com/newsroom/article/aramark-announces-vestis',
      title: 'Read more here.',
      target: '',
      commonLink: true,
    },
    image: {
      brandfolder_asset:
        'https://cdn.bfldr.com/4IC075XI/at/nkqwwn9c2cqffsqj8jwjx4mb/Aramark_New_PMS.eps?auto=webp&format=jpg&width=186&height=47',
      alt_text: 'Aramark logo',
      title: 'Aramark logo',
    },
    variant: 'portal',
  };

  constructor(public ps: PlatformService) {
    if (this.ps.isBrowser()) {
      setTimeout(() => {
        this.showSpinner = false;
      }, 0);
    }
  }
}
