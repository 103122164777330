import { Injectable } from '@angular/core';
import { TransferStateService } from 'src/app/services/transfer-state/transfer-state.service';

@Injectable({
  providedIn: 'root',
})
export class UrlsService {
  baseUri: string = '';
  constructor(private tsService: TransferStateService) {
    this.baseUri = this.tsService.getBaseUri();
  }

  getLogin(): string {
    return this.baseUri + '/sso/v1/sdk/authenticate';
  }

  getPassword(): string {
    return this.baseUri + '/admin/v1/MePasswordRecoveryOptionRetriever';
  }

  getForgotPassword(): string {
    return this.baseUri + '/admin/v1/MePasswordResetRequestor';
  }

  getSession(): string {
    return this.baseUri + '/sso/v1/sdk/session';
  }

  getProcessPasswordMethod(): string {
    return this.baseUri + '/admin/v1/MePasswordRecoveryFactorValidator';
  }

  getEvaluatePasswordPolicies(): string {
    return this.baseUri + '/admin/v1/UserPasswordValidator';
  }

  getResetUserPassword(): string {
    return this.baseUri + '/admin/v1/MePasswordResetter';
  }

  getSetUserPassword(): string {
    return this.baseUri + '/admin/v1/Me';
  }

  getSelfRegistration(): string {
    return '/self-registration';
  }

  getOutageInfo(): string {
    return '/outage-info';
  }

  getValidateInviteToken(): string {
    return '/validate-invite-token';
  }

  getPromoteExistingUser(): string {
    return '/promote-existing-user';
  }

  getAccountHasAdmin(accountId: string): string {
    return `/account-has-admin/${accountId}`;
  }

  getAccountHasNAID(accountId: string): string {
    return `/account-has-naid/${accountId}`;
  }
}
