import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignRoutingModule } from './sign-in-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { OneTimeService, PlatformService } from 'universal';
import {
  AnnouncementModule,
  SideImgLayoutModule,
} from '@vestis/ngx-design-system';
import { RouterModule } from '@angular/router';
import { GenesysChatComponent } from './components/genesys-chat/genesys-chat.component';
import { SignInComponent } from './sign-in.component';
import { SignTranslatePipe } from './pipes/translate/sign-in-translate.pipe';

@NgModule({
  declarations: [
    SignInComponent,
    SignTranslatePipe,
    GenesysChatComponent
  ],
  imports: [
    CommonModule,
    SignRoutingModule,
    HttpClientModule,
    RouterModule,
    AnnouncementModule,
    SideImgLayoutModule,
  ],
  providers: [PlatformService, OneTimeService],
})
export class SignInModule {}
