import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-google-analytics',
  templateUrl: './google-analytics.component.html',
  styleUrls: ['./google-analytics.component.scss'],
})
export class GoogleAnalyticsComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    @Inject(DOCUMENT) private document: Document
  ) {
    // BROWSER
    if (isPlatformBrowser(this.platformId)) {
      const scriptGtag = this.document.createElement('script') as HTMLScriptElement;
      scriptGtag.async = true;
      this.document.head.appendChild(scriptGtag);

      const scriptInit = this.document.createElement('script') as HTMLScriptElement;
      const scriptBody = this.document.createTextNode(`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-52NBWH2');
      `);
        
      scriptInit.appendChild(scriptBody);
      this.document.head.appendChild(scriptInit);

      const noScriptTag = this.document.createElement('noscript') as HTMLElement;
      const noScriptIframe = this.document.createElement('iframe') as HTMLIFrameElement;
      noScriptIframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-52NBWH2";
      noScriptIframe.height = '0';
      noScriptIframe.width = '0';
      noScriptTag.appendChild(noScriptIframe);
      this.document.body.appendChild(noScriptTag);
    }
  }

  ngOnInit(): void {
      
  }
}
