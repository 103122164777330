import { Component, inject } from '@angular/core';

import { BrowserCompatibilityService } from './services/browser-compatibility/browser-compatibility.service';
import { PlatformService } from 'universal';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  bcs = inject(BrowserCompatibilityService);
  bgUrl = "https://cdn.bfldr.com/4IC075XI/at/xj4qm9hwnqk3gct2gjzptcz/Portal_Hero_Image_Vestis.jpg?auto=webp&format=jpg";

  constructor(public ps: PlatformService) {}
}
