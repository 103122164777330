import { OutageGuard } from './modules/sign-in/guard/outage/outage.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAcknowledgementComponent } from '@sign-in/components/admin-acknowledgement/admin-acknowledgement.component';
import { BrowserCompatibilityGuard } from '@sign-in/guard/browser-compatibility/browser-compatibility.guard';
import { LoginGuard } from '@sign-in/guard/login/login.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/sign-in/sign-in.module').then((m) => m.SignInModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'admin_invite',
    loadComponent: () => import('./modules/sign-in/components/admin-acknowledgement/admin-acknowledgement.component').then((m) => m.AdminAcknowledgementComponent),
    canActivate: [OutageGuard, BrowserCompatibilityGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
