import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { tap, map } from 'rxjs';

import { OutagesService } from 'src/app/pages/outage/outage.service';
import { PlatformService } from 'universal';

@Injectable({
  providedIn: 'root',
})
export class OutageGuard  {
  os = inject(OutagesService);
  router = inject(Router);
  ps = inject(PlatformService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.ps.isServer()) return true;
    const { activateIfNoOutage = true }: { activateIfNoOutage?: boolean } =
      route.data;
    const call = this.os.getOutageInfo();
    if (activateIfNoOutage) {
      return call.pipe(
        map((res) => {
          return (
            res.currentOutage?.outage === 'false' ||
            res.allOutages.emergency.enabled === 'false'
          );
        }),
        tap((noActiveOutage) => {
          // Protects from accessing outage page if there is no active outage
          if (noActiveOutage && state.url.includes('/outage')) {
            this.router.navigateByUrl('/login');
            return;
          }
          // Forces redirection to outage page from any other route if there is an active outage
          if (!noActiveOutage && !state.url.includes('/outage')) {
            this.router.navigateByUrl('/outage');
            return;
          }
        })
      );
    }
    return call.pipe(
      map((res) => {
        return (
          res.currentOutage?.outage === 'true' ||
          res.allOutages.emergency.enabled === 'true'
        );
      }),
      tap((isActiveOutage) => {
        // Protects from accessing outage page if there is no active outage
        if (!isActiveOutage && state.url.includes('/outage')) {
          this.router.navigateByUrl('/login');
          return;
        }
        // Forces redirection to outage page from any other route if there is an active outage
        if (isActiveOutage && !state.url.includes('/outage')) {
          this.router.navigateByUrl('/outage');
          return;
        }
      })
    );
  }
}
