import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { UrlsService } from './../../modules/sign-in/services/urls/urls.service';

export interface Outage {
  outageNumber: string;
  startDate: string;
  endDate: string;
  outageLevel: 'Site';
  outageMessage: string;
  updatedDate: string;
  updatedBy: string;
}

export interface EmergencyOutage {
  enabled: 'true' | 'false';
  updatedDate: string;
  updatedBy: string;
}

export interface CurrentOutage {
  outage: 'true' | 'false';
  outages: Outage[];
}

@Injectable({
  providedIn: 'root',
})
export class OutagesService {
  private http = inject(HttpClient);
  private urls = inject(UrlsService);

  getOutageInfo() {
    return this.http.get<{
      currentOutage: CurrentOutage;
      allOutages: { outages: Outage[]; emergency: EmergencyOutage };
    }>(this.urls.getOutageInfo());
  }
}
