import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { provideNgxMask } from 'ngx-mask';
import {
  AlertBannerModule,
  SideImgLayoutModule,
  SpinnerModule,
} from '@vestis/ngx-design-system';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInModule } from './modules/sign-in/sign-in.module';
import { TransferStateService } from './services/transfer-state/transfer-state.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleAnalyticsComponent } from '@sign-in/components/google-analytics/google-analytics.component';

@NgModule({
  declarations: [
    AppComponent,
    GoogleAnalyticsComponent,
  ],
  imports: [
    SignInModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    SideImgLayoutModule,
    SpinnerModule,
    AlertBannerModule,
  ],
  providers: [TransferStateService, provideNgxMask()],
  bootstrap: [AppComponent],
})
export class AppModule {}
