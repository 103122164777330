import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TransferStateService } from 'src/app/services/transfer-state/transfer-state.service';
import { PlatformService } from 'universal';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(
    private tsService: TransferStateService,
    private ps: PlatformService
  ) {}

  private needToReloadStateAndAT(route: ActivatedRouteSnapshot) {
    if (route.routeConfig?.path !== 'login') return false;
    if (
      !sessionStorage.getItem('signinAT') ||
      sessionStorage.getItem('signNeedRefresh')
    )
      return true;
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.ps.isBrowser() && this.needToReloadStateAndAT(route)) {
      // Save the url to redirect after going to oracle url.
      sessionStorage.setItem('redirectAfterSingAt', state.url);
      window.location.replace('/initiateLogin');
      return false;
    }
    return true;
  }
}
