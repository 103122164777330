import { Component, inject } from '@angular/core';
import { NgIf } from '@angular/common';

import { BrowserCompatibilityService } from '@sign-in/services/browser-compatibility/browser-compatibility.service';
import { UrlsService } from '@sign-in/services/urls/urls.service';
import { PlatformService } from 'universal';
import { OutdatedBrowserModule } from '@vestis/ngx-design-system';

@Component({
  selector: 'app-browser-compatibility',
  templateUrl: './browser-compatibility.component.html',
  styleUrls: ['./browser-compatibility.component.scss'],
  standalone: true,
  imports: [NgIf, OutdatedBrowserModule],
})
export class BrowserCompatibilityComponent {
  ps = inject(PlatformService);
  bcs = inject(BrowserCompatibilityService);
  us = inject(UrlsService); // Important to set up baseUri

  onContinue() {
    this.bcs.acknowledge();
  }
}
