import { Injectable, inject } from '@angular/core';
import { PlatformService } from 'universal';

@Injectable({
  providedIn: 'root',
})
export class BrowserCompatibilityService {
  ps = inject(PlatformService);

  private ACK_KEY = 'armk-outdated-browser-ack';

  getDidAck() {
    if (this.ps.isBrowser()) {
      return localStorage.getItem(this.ACK_KEY) === 'true';
    }
    return false;
  }

  acknowledge() {
    if (this.ps.isBrowser()) {
      localStorage.setItem(this.ACK_KEY, 'true');
    }
  }

  clear() {
    if (this.ps.isBrowser()) {
      localStorage.removeItem(this.ACK_KEY);
    }
  }
}
