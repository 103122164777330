import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from 'src/app/pages/error/error.component';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { LoginGuard } from './guard/login/login.guard';
import { SignInComponent } from './sign-in.component';
import { OutageComponent } from 'src/app/pages/outage/outage.component';
import { OutageGuard } from './guard/outage/outage.guard';
import { BrowserCompatibilityGuard } from './guard/browser-compatibility/browser-compatibility.guard';
import { BrowserCompatibilityComponent } from 'src/app/pages/browser-compatibility/browser-compatibility.component';;

const routes: Routes = [
  {
    path: '',
    component: SignInComponent,
    children: [
      {
        path: 'login',
        loadComponent: () => import('./components/login-form-formly/login-form-formly.component').then((m) => m.LoginFormFormlyComponent),
        canActivate: [LoginGuard, OutageGuard, BrowserCompatibilityGuard],
      },
      {
        path: 'logged-out',
        loadComponent: () => import('./components/logged-out/logged-out.component').then((m) => m.LoggedOutComponent),
        canActivate: [LoginGuard, OutageGuard, BrowserCompatibilityGuard],
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
      {
        path: 'forgot-password',
        loadComponent: () => import('./components/forgot-password-form/forgot-password-form.component').then((m) => m.ForgotPasswordFormComponent),
        canActivate: [OutageGuard, BrowserCompatibilityGuard],
      },
      {
        path: 'self-registration',
        loadComponent: () => import('./components/self-registration-form-formly/self-registration-form-formly.component').then((m) => m.SelfRegistrationFormFormlyComponent),
        canActivate: [OutageGuard, BrowserCompatibilityGuard],
      },
      {
        path: 'error',
        loadComponent: () => import('../../pages/error/error.component').then((m) => m.ErrorComponent),
        canActivate: [OutageGuard, BrowserCompatibilityGuard],
      },
      {
        path: 'outage',
        loadComponent: () => import('../../pages/outage/outage.component').then((m) => m.OutageComponent),
        canActivate: [OutageGuard, BrowserCompatibilityGuard],
        data: { activateIfNoOutage: false },
      },
    ],
  },
  {
    path: 'outdated-browser',
    component: BrowserCompatibilityComponent,
    canActivate: [BrowserCompatibilityGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SignRoutingModule {}
