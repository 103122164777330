<ng-container *ngIf="ps.isBrowser()">
  <aus-announcement
    *ngIf="bcs.getDidAck()"
    text="You’re using an outdated browser. Upgrade your browser to ensure your experience is smooth and secure."
    [link]="{
      text: 'Supported Browsers',
      route: 'outdated-browser'
    }"
  ></aus-announcement>
  <!-- <app-genesys-chat></app-genesys-chat> -->
  <aus-side-img-layout
    [bgUrl]="bgUrl"
  >
    <router-outlet></router-outlet>
  </aus-side-img-layout>
</ng-container>