import { Inject, Injectable, Optional, TransferState } from '@angular/core';
import { AT, BASE_URI, BASE_URL } from 'keys';

@Injectable({
  providedIn: 'root',
})
export class TransferStateService {
  constructor(
    @Optional()
    @Inject(BASE_URI)
    private baseUri: string,
    @Optional()
    @Inject(BASE_URL)
    private baseUrl: string,
    @Optional()
    @Inject(AT)
    private appAccessToken: string,
    private state: TransferState
  ) {
    if (this.baseUri) {
      this.state.set(BASE_URI, this.baseUri);
    }
    if (this.baseUrl) {
      this.state.set(BASE_URL, this.baseUrl);
    }
    if (this.appAccessToken) {
      this.state.set(AT, this.appAccessToken);
    }
  }

  getBaseUri() {
    return this.state.get(BASE_URI, '');
  }
  getBaseUrl() {
    return this.state.get(BASE_URL, '');
  }
  getAT() {
    return this.state.get(AT, '');
  }
}
